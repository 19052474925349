import Cookies from "js-cookie";
import { adminPathName } from "types/types";

export function getClusterTokenFromCookies({ isAdmin }: { isAdmin: boolean }) {
  const clusterName = isAdmin
    ? adminPathName
    : window.location.pathname.split("/cluster/")[1].split("/")[0];
  return Cookies.get(clusterName);
}

export function setToken({
  clusterName,
  token,
}: {
  clusterName: string;
  token: string;
}) {
  const path =
    clusterName === adminPathName
      ? `/${adminPathName}`
      : `/cluster/${clusterName}`;
  Cookies.set(clusterName, token, {
    path,
  });
}
